/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useState } from 'react';

import { useRouter } from 'next/router';

import { ECImage, Icon, SectionItem } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import { useTracking } from 'context/TrackingProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';

const Influencer = (): JSX.Element => {
  const { t } = useTranslations('content.influencer');
  const { img } = useImages(ImageSetKeys.ContentInfluencer);
  const { countryCode, isLoading: isLoadingCountryContext } = useCountryContext();
  const { locale } = useRouter();
  const [playBtnClicked, setPlayBtnClicked] = useState(true);
  const videoRef = useRef<HTMLVideoElement>();

  const {
    events: { trackCtaClicked },
  } = useTracking();

  const playVideo = () => {
    if (videoRef?.current) {
      videoRef.current.play();
    }
  };

  if (isLoadingCountryContext) {
    return <></>;
  }

  if (countryCode === 'BE' && (locale === 'nl' || locale === 'fr')) {
    const influencer = {
      sticker: img('sophieDumontSticker'),
      image: img('sophieDumontThumbnail'),
      description: t('sophieDumontDescription'),
      video: img('sophieDumontVideo'),
    };
    return (
      <SectionItem>
        <div className="maxLg:pt-12">
          <div className="container container--lg">
            <div className="lg:bg-white rounded-xl lg:py-10 lg:px-20 flex flex-col lg:flex-row-reverse lg:gap-20 gap-4 items-center">
              <div className="relative w-full sm:max-w-[20.5rem] lg:max-w-full">
                <h3 className="text-xl lg:text-2xl text-center font-medium font-rooney text-purple-700 bg-white rounded-2xl lg:p-0 py-8 px-4">
                  {influencer.description}
                </h3>
                <ECImage
                  img={influencer.sticker}
                  className="absolute -top-14 left-1/2 right-1/2 transform -translate-x-1/2 lg:hidden"
                />
              </div>
              <div className="relative">
                <video
                  className="rounded-4xl sm:max-w-[20.5rem]"
                  poster={influencer.image?.src}
                  controls={!playBtnClicked}
                  preload="auto"
                  ref={videoRef}
                >
                  <source src={influencer.video?.src} type="video/mp4" />
                </video>
                <button
                  className="absolute top-1/2 bottom-1/2 left-1/2 right-1/2 transform -translate-x-1/2 cursor-pointer"
                  type="button"
                  onClick={() => {
                    setPlayBtnClicked(false);
                    playVideo();
                    trackCtaClicked({ ctaId: 'play_video_sofie_dumont' });
                  }}
                  hidden={!playBtnClicked}
                >
                  <Icon
                    name="video-play"
                    className="w-24 text-purple-500 absolute top-1/2 bottom-1/2 left-1/2 right-1/2 transform -translate-x-1/2"
                  />
                </button>
                <ECImage
                  img={influencer.sticker}
                  className="absolute -top-4 -left-8 maxLg:hidden"
                />
              </div>
            </div>
          </div>
        </div>
      </SectionItem>
    );
  }

  if (countryCode === 'GB') {
    // deliciously ella
    const influencer = {
      image: img('deliciouslyElla'),
      description: t('deliciouslyEllaDescription'),
      sticker: img('deliciouslyEllaSticker'),
      logo: img('deliciouslyEllaLogo'),
    };

    return (
      <SectionItem>
        <div className="pt-8">
          <div className="container maxXl:max-w-copy">
            <div className="relative bg-white rounded-2xl">
              <ECImage
                img={influencer.sticker}
                className="absolute xl:-top-8 xl:left-3/4 xl:right-1/4 transform -translate-x-1/2 -top-12 left-1/2 right-1/2"
              />
              <div className="grid grid-cols-1 xl:grid-cols-2 xl:grid-rows-1 grid-rows-2 justify-center">
                <ECImage
                  img={influencer.image}
                  pictureClassName="min-w-full min-h-full"
                  className="min-w-full min-h-full object-cover rounded-t-2xl"
                />
                <div className="flex flex-col items-center justify-center text-center font-rooney font-bold text-gray-500 p-4 pt-6 pb-8">
                  <span className="mb-4 text-2xl">{t('fromDeliciouslyElla')}</span>
                  <ECImage
                    img={{ src: influencer.logo?.src, alt: 'Deliciously Ella' }}
                    className="w-full mx-auto grow maxLg:max-w-xs max-w-[27rem] px-4"
                  />
                  <h3 className="mt-8 text-xl xl:text-2xl max-w-sm">{influencer.description}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionItem>
    );
  }

  if (countryCode === 'FR' && locale === 'fr') {
    // Chloé and you
    const influencer = {
      image: img('chloeAndYou'),
      description: t('chloeAndYouDescription'),
      sticker: img('chloeAndYouSticker'),
      logo: img('chloeAndYouLogo'),
    };

    return (
      <SectionItem>
        <div className="pt-8">
          <div className="container container--lg maxXl:max-w-copy">
            <div className="relative bg-white rounded-2xl">
              <ECImage
                img={influencer.sticker}
                className="absolute xl:-top-8 xl:left-3/4 xl:right-1/4 transform -translate-x-1/2 -top-10 left-1/2 right-1/2 z-10"
              />
              <div className="grid grid-cols-1 xl:grid-cols-2 justify-center">
                <ECImage
                  img={influencer.image}
                  pictureClassName="aspect-w-4 aspect-h-3 xl:aspect-h-4"
                  className="min-w-full min-h-full object-cover maxXl:rounded-t-2xl xl:rounded-tl-2xl xl:rounded-bl-2xl"
                />
                <div className="flex flex-col items-center justify-center text-center font-rooney font-medium text-gray-500 px-4 p-8 xl:pt-16">
                  <span className="mb-4 text-xl">{t('fromInfluencer')}</span>
                  <ECImage
                    img={{ src: influencer.logo?.src, alt: 'Chloé and you' }}
                    className="w-full mx-auto grow max-w-xxxs xl:max-w-xxs"
                  />
                  <p className="mt-8 text-lg max-w-sm">{influencer.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionItem>
    );
  }

  if (countryCode === 'DE' && locale === 'de') {
    const influencer = {
      sticker: img('marenWolfSticker'),
      image: img('marenWolfThumbnail'),
      description: t('marenWolfDescription'),
      video: img('marenWolfVideo'),
    };
    return (
      <SectionItem>
        <div className="maxLg:pt-12">
          <div className="container container--lg">
            <div className="lg:bg-white rounded-xl lg:py-10 lg:px-20 flex flex-col lg:flex-row-reverse lg:gap-20 gap-4 items-center">
              <div className="relative w-full sm:max-w-[20.5rem] lg:max-w-full">
                <h3 className="text-xl lg:text-2xl text-center font-medium font-rooney text-purple-700 bg-white rounded-2xl lg:p-0 py-8 px-4">
                  {influencer.description}
                </h3>
                <ECImage
                  height={85}
                  width={185}
                  img={influencer.sticker}
                  className="absolute -top-14 left-1/2 right-1/2 transform -translate-x-1/2 lg:hidden"
                />
              </div>
              <div className="relative">
                <video
                  className="rounded-4xl sm:max-w-[20.5rem]"
                  poster={influencer.image?.src}
                  controls={!playBtnClicked}
                  preload="auto"
                  ref={videoRef}
                >
                  <source src={influencer.video?.src} type="video/mp4" />
                </video>
                <button
                  className="absolute top-1/2 bottom-1/2 left-1/2 right-1/2 transform -translate-x-1/2 cursor-pointer"
                  type="button"
                  onClick={() => {
                    setPlayBtnClicked(false);
                    playVideo();
                    trackCtaClicked({ ctaId: 'play_video_sofie_dumont' });
                  }}
                  hidden={!playBtnClicked}
                >
                  <Icon
                    name="video-play"
                    className="w-24 text-purple-500 absolute top-1/2 bottom-1/2 left-1/2 right-1/2 transform -translate-x-1/2"
                  />
                </button>
                <ECImage
                  height={85}
                  width={185}
                  img={influencer.sticker}
                  className="absolute -top-4 -left-8 maxLg:hidden"
                />
              </div>
            </div>
          </div>
        </div>
      </SectionItem>
    );
  }

  if (countryCode === 'NL' && locale === 'nl') {
    // Nicolette Kluijver
    const influencer = {
      image: img('heroNicolette'), // Same image as hero image
      description: t('NicoletteKluijverDescription'),
      video: img('nicoletteVideo'),
      sticker: img('nicoletteSticker'),
    };

    return (
      <SectionItem>
        <div className="maxLg:pt-12">
          <div className="container container--lg">
            <div className="lg:bg-white rounded-xl lg:py-10 lg:px-20 flex flex-col lg:flex-row-reverse lg:gap-20 gap-4 items-center">
              <div className="relative w-full sm:max-w-[20.5rem] lg:max-w-full">
                <h3 className="text-xl lg:text-2xl text-center font-medium font-rooney text-purple-700 bg-white rounded-2xl lg:p-0 py-8 px-4">
                  {influencer.description}
                </h3>
                <ECImage
                  img={influencer.sticker}
                  height={85}
                  width={185}
                  className="absolute -top-16 left-1/2 right-1/2 transform -translate-x-1/2 lg:hidden"
                />
              </div>
              <div className="relative">
                <video
                  className="rounded-4xl sm:max-w-[20.5rem]"
                  poster={influencer.image?.src}
                  controls={!playBtnClicked}
                  preload="auto"
                  ref={videoRef}
                >
                  <source src={influencer.video?.src} type="video/mp4" />
                </video>
                <button
                  className="absolute top-1/2 bottom-1/2 left-1/2 right-1/2 transform -translate-x-1/2 cursor-pointer"
                  type="button"
                  onClick={() => {
                    setPlayBtnClicked(false);
                    playVideo();
                    trackCtaClicked({ ctaId: 'play_video_sofie_dumont' });
                  }}
                  hidden={!playBtnClicked}
                >
                  <Icon
                    name="video-play"
                    className="w-24 text-purple-500 absolute top-1/2 bottom-1/2 left-1/2 right-1/2 transform -translate-x-1/2"
                  />
                </button>
                <ECImage
                  height={85}
                  width={185}
                  img={influencer.sticker}
                  className="absolute -top-6 -right-8 maxLg:hidden"
                />
              </div>
            </div>
          </div>
        </div>
      </SectionItem>
    );
  }

  return <></>;
};

export default Influencer;
